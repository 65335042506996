import { CalculatorIcon, ReceiptTaxIcon, UserCircleIcon, ViewGridIcon } from '@heroicons/react/outline';
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from '../actions/auth';
import { Link as RouterLink } from 'react-router-dom';
import DrawerMenu from '../components/DrawerMenu'
import axios from 'axios';
import Swal from 'sweetalert2'

const DefaultNew = (props) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false)

    const { user } = useSelector((state) => state.auth);

    const handleClickLogout = () => {

        dispatch(logout())
            .then((response) => {

            })
            .catch((err) => {
                console.log(err)
            });
    }

    const handleClickUpdate = () => {
        try {
            axios.post(`https://api.netlify.com/build_hooks/64e8d5bafb981f1135acead8`)
                .then((data) => {
                    Swal.fire({
                        position: 'top-center',
                        icon: 'success',
                        title: 'Actualización enviada con exito',
                        showConfirmButton: false,
                        timer: 1500
                    })

                })
                .catch((error) => {
                    console.log(error)
                })
        } catch (error) {
            console.log(error)
        }

    }

    return (
        <div className="flex min-h-screen  max-h-screen h-full" >
            <DrawerMenu open={open} onClose={setOpen} />
            <div className="hidden md:flex relative">
                <nav className="flex flex-col w-14  bg-gradient-to-b from-gray-900 via-gray-800 to-gray-700" style={{
                    maxWidth: "56px",
                    zIndex: 40
                }}>
                    <div className="flex flex-col w-14 flex-1  sticky top-0" style={{
                        maxWidth: "56px",
                        zIndex: 40
                    }}>
                        <RouterLink to='/images' className="relative flex justify-center items-center p-4 text-white group hover:text-white hover:bg-gray-800" style={{ "WebkitBoxPack": "center" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                            </svg>
                            <div className="absolute bg-gray-800 rounded-md left-full ml-1 hidden group-hover:block px-2 py-1 text-xs top-auto mt-1">
                                Imágenes
                            </div>
                        </RouterLink>
                        <RouterLink to='/products' className="relative flex justify-center items-center p-4 text-white group hover:text-white hover:bg-gray-800" style={{ "WebkitBoxPack": "center" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 16 16" fill="currentColor">
                                <path d="M14 7v6.39a.6.6 0 0 1-.6.61H2.6a.6.6 0 0 1-.6-.61v-6.4h12V7zm-2 3H8v2h4v-2zm-.9-8c.19 0 .37.1.48.25L14 6H9V2h2.1zM7 2v4H2l2.43-3.75A.6.6 0 0 1 4.92 2H7z"></path>
                            </svg>
                            <div className="absolute bg-gray-800 rounded-md left-full ml-1 hidden group-hover:block px-2 py-1 text-xs top-auto mt-1">
                                Productos
                            </div>
                        </RouterLink>

                        <RouterLink to='/categories' className="relative flex justify-center items-center p-4 text-white group hover:text-white hover:bg-gray-800" style={{ "WebkitBoxPack": "center" }}>
                            <ViewGridIcon className='h-6 w-6 mx-auto' />
                            <div className="absolute bg-gray-800 rounded-md left-full ml-1 hidden group-hover:block px-2 py-1 text-xs top-auto mt-1">
                                Categorias
                            </div>
                        </RouterLink>
                        <RouterLink to='/brands' className="relative flex justify-center items-center p-4 text-white group hover:text-white hover:bg-gray-800" style={{ "WebkitBoxPack": "center" }}>
                            <ViewGridIcon className='h-6 w-6 mx-auto' />
                            <div className="absolute bg-gray-800 rounded-md left-full ml-1 hidden group-hover:block px-2 py-1 text-xs top-auto mt-1">
                                Marcas
                            </div>
                        </RouterLink>
                        {/*
                        <RouterLink to='/quotes' className="relative flex justify-center items-center p-4 text-white group hover:text-white hover:bg-gray-800" style={{ "WebkitBoxPack": "center" }}>
                            <FolderOpenIcon className='h-6 w-6 mx-auto' />
                            <div className="absolute bg-gray-800 rounded-md left-full ml-1 hidden group-hover:block px-2 py-1 text-xs top-auto mt-1">
                                Cotizaciones
                            </div>
                        </RouterLink>
                        <a target='_blank' href='/quoter' className="relative flex justify-center items-center p-4 text-white group hover:text-white hover:bg-gray-800" style={{ "WebkitBoxPack": "center" }}>
                            <CalculatorIcon className='h-6 w-6 mx-auto' />
                            <div className="absolute bg-gray-800 rounded-md left-full ml-1 hidden group-hover:block px-2 py-1 text-xs top-auto mt-1">
                                Cotizador
                            </div>
                        </a>
                        <RouterLink to='/facturador' className="relative flex justify-center items-center p-4 text-white group hover:text-white hover:bg-gray-800" style={{ "WebkitBoxPack": "center" }}>
                            <ReceiptTaxIcon className='h-6 w-6 mx-auto' />
                            <div className="absolute bg-gray-800 rounded-md left-full ml-1 hidden group-hover:block px-2 py-1 text-xs top-auto mt-1">
                                Facturador
                            </div>
                        </RouterLink>
                            */}
                    </div>
                    <div>
                        <button onClick={handleClickLogout} className="relative flex justify-center items-center p-4 text-white group hover:text-white hover:bg-gray-800" style={{ "WebkitBoxPack": "center" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z" clipRule="evenodd" />
                            </svg>
                            <div className="absolute bg-gray-800 rounded-md left-full ml-1 hidden group-hover:block px-2 py-1 text-xs top-auto mt-1">
                                Salir
                            </div>
                        </button>
                    </div>
                    {/*
                    <a className="relative flex justify-center items-center p-4 text-gray-200 group hover:text-white hover:bg-gray-800" style={{ "WebkitBoxPack": "center" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 24 24" fill="currentColor">
                            <path d="M21.9 8.89l-1.05-4.37c-.22-.9-1-1.52-1.91-1.52H5.05c-.9 0-1.69.63-1.9 1.52L2.1 8.89a3.38 3.38 0 0 0 .62 2.88c.08.11.19.19.28.29V19c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2v-6.94c.09-.09.2-.18.28-.28.64-.82.87-1.87.62-2.89zm-2.99-3.9l1.05 4.37c.1.42.01.84-.25 1.17-.14.18-.44.47-.94.47-.61 0-1.14-.49-1.21-1.14L16.98 5l1.93-.01zM13 5h1.96l.54 4.52c.05.39-.07.78-.33 1.07a1.2 1.2 0 0 1-.95.41c-.67 0-1.22-.59-1.22-1.31V5zM8.49 9.52L9.04 5H11v4.69c0 .72-.55 1.31-1.29 1.31a1.2 1.2 0 0 1-.89-.41 1.42 1.42 0 0 1-.33-1.07zm-4.45-.16L5.05 5h1.97l-.58 4.86c-.08.65-.6 1.14-1.21 1.14-.49 0-.8-.29-.93-.47a1.32 1.32 0 0 1-.26-1.17zM5 19v-6.03c.08.01.15.03.23.03.87 0 1.66-.36 2.24-.95a3.22 3.22 0 0 0 4.54.02 3.18 3.18 0 0 0 4.53-.02c.58.59 1.37.95 2.24.95.08 0 .15-.02.23-.03V19H5z"></path>
                        </svg>
                        <div className="absolute bg-gray-800 rounded-md left-full ml-1 hidden group-hover:block px-2 py-1 text-xs top-auto mt-1">
                            MarketPlace
                        </div>
                    </a>
                    <a className="relative flex justify-center items-center p-4 text-gray-200 group hover:text-white hover:bg-gray-800" style={{ "WebkitBoxPack": "center" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 24 24" fill="currentColor">
                            <path d="M8.35 15.88l-.9-6.9c5.32-1.66 8.06-4.17 9.3-5.66.4-.48 1.24-.42 1.44.17a39.4 39.4 0 0 1 1.6 7.37c.47 3.53.45 6.06.36 7.53-.04.62-.83.9-1.34.54-1.59-1.12-4.89-2.83-10.46-3.05zm-6.53-3.66c-.15-1.1.63-2.1 1.72-2.3 1.05-.19 2.02-.41 2.93-.66l.87 6.6a35.1 35.1 0 0 0-3 .11 2.09 2.09 0 0 1-2.26-1.77l-.26-1.98zm20.95-1.75a3 3 0 0 1-1.68 3.1 50.42 50.42 0 0 0-.75-5.66 3 3 0 0 1 2.43 2.56zM7.83 16.86h-.5c-.63 0-1.29 0-1.98.05l1.94 4.66a1.5 1.5 0 0 0 2.77-1.14L8.6 16.89l-.29-.01-.48-.02z"></path>
                        </svg>
                        <div className="absolute bg-gray-800 rounded-md left-full ml-1 hidden group-hover:block px-2 py-1 text-xs top-auto mt-1">
                            MarketPlace
                        </div>
                    </a>
                    */}
                </nav>
            </div>
            <div className="flex flex-col w-full max-h-screen h-full overflow-hidden">
                <div className="flex w-full z-20 h-14  shadow-sm sticky top-0">
                    <div className="flex overflow-hidden pl-4 flex-grow " >
                        <button onClick={() => setOpen(!open)} className="md:hidden mr-4 inline-flex items-center justify-center p-2 rounded-md text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                            <span className="sr-only">Open main menu</span>
                            <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                            </svg>
                            <svg className="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                        <div className="flex py-2 overflow-hidden">
                            <img className='h-6 my-auto' src='https://d2ja6cy9ij2jx1.cloudfront.net/fit-in/600x200/delicores-logo-black.png' />
                        </div>
                    </div>
                    <div className="flex  justify-end items-center overflow-hidden pr-3 ml-3 space-x-2">
                        <button className='ml-4' type='button' onClick={handleClickUpdate}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                            </svg>
                        </button>
                    </div>
                </div>
                <div className="relative flex w-full h-full max-h-full overflow-auto">
                    {props.children}
                </div>
            </div>
        </div >

    );
}



export default DefaultNew;
