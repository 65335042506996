import React, { useEffect } from 'react';
import classnames from 'classnames'
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { CalculatorIcon, ViewGridAddIcon, ViewGridIcon } from '@heroicons/react/outline';
import { logout } from '../../actions/auth';
import { useDispatch, useSelector } from 'react-redux';
import { FolderOpenIcon, ReceiptTaxIcon } from '@heroicons/react/solid';

const DrawerComponent = (props) => {

    const { open, onClose } = props

    //const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleChangeRoute = (link) => {
        //navigate.push(link);
        navigate(link, { replace: true });
        onClose()
    }

    const handleClickLogout = () => {

        dispatch(logout())
            .then((response) => {

            })
            .catch((err) => {
                console.log(err)
            });
    }

    var fondoClass = classnames({
        "z-10 fixed inset-0 transition-opacity ease-in-out duration-1000": true,
        "opacity-0": !open,
        "hidden": !open,
        "invisible": !open
    })

    var drawerClass = classnames({
        "transform top-0 left-0  sm:w-96 max-w-85/100 text-white fixed h-full max-h-full overflow-auto ease-in-out transition-all duration-300 z-30  bg-gradient-to-b from-gray-900 via-gray-800 to-gray-700": true,
        "-translate-x-full": !open,
        "invisible": !open
        //"translate-x-0": !open
    })

    //    useOnClickOutside(ref, () => { setOpen(false); console.log("CLICK FUERA") });
    //
    //    const toggleDrawer = (anchor, openState) => (event) => {
    //
    //        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
    //            return;
    //        }
    //
    //        setOpen(openState);
    //    };

    useEffect(() => {
        document.addEventListener("keydown", (event) => {
            if ((event.key === 'Escape' || event.key === 'Esc')) {
                onClose()
            }
        })

        return () => {
            document.removeEventListener("keydown", (event) => {
                if ((event.key === 'Escape' || event.key === 'Esc')) {
                    onClose()
                }
            })
        };
    }, []);


    useEffect(() => {
        if (open) {
            document.body.style.setProperty("overflow", "hidden");
        }
        else {
            document.body.style.removeProperty("overflow");
        }
    }, [open]);


    return (
        <div>
            <div className="relative z-30 max-h-full" >
                <div className={fondoClass}>
                    <div tabIndex="0" onClick={() => onClose()} className="absolute inset-0 bg-black opacity-50" />
                </div>
                <div className={drawerClass}>
                    <div className='h-full w-full flex flex-col space-y-2 pt-2'>
                        <div className='flex-1 overflow-y-auto'>
                            <ul className="h-full w-full " style={{ minWidth: 200 }}>
                                <li>
                                    <button type='button' onClick={() => handleChangeRoute('/images')} className="flex items-center w-full p-4 text-white group hover:text-white hover:bg-gray-800">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                        </svg>
                                        <span>
                                            Imagenes
                                        </span>
                                    </button>
                                </li>
                                <li>
                                    <button type='button' onClick={() => handleChangeRoute('/products')} className="flex items-center w-full p-4 text-white group hover:text-white hover:bg-gray-800">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-4" viewBox="0 0 16 16" fill="currentColor">
                                            <path d="M14 7v6.39a.6.6 0 0 1-.6.61H2.6a.6.6 0 0 1-.6-.61v-6.4h12V7zm-2 3H8v2h4v-2zm-.9-8c.19 0 .37.1.48.25L14 6H9V2h2.1zM7 2v4H2l2.43-3.75A.6.6 0 0 1 4.92 2H7z"></path>
                                        </svg>
                                        <span>
                                            Productos
                                        </span>
                                    </button>
                                </li>
                                <li>
                                    <button type='button' onClick={() => handleChangeRoute('/categories')} className="flex items-center w-full p-4 text-white group hover:text-white hover:bg-gray-800">
                                        <ViewGridIcon className="h-6 w-6 mr-4" />
                                        <span>
                                            Categories
                                        </span>
                                    </button>
                                </li>
                                <li>
                                    <button type='button' onClick={() => handleChangeRoute('/brands')} className="flex items-center w-full p-4 text-white group hover:text-white hover:bg-gray-800">
                                        <ViewGridIcon className="h-6 w-6 mr-4" />
                                        <span>
                                            Marcas
                                        </span>
                                    </button>
                                </li>
                                {/*
                                <li>
                                    <button type='button' onClick={() => handleChangeRoute('/facturador')} className="flex items-center w-full p-4 text-white group hover:text-white hover:bg-gray-800">
                                        <ReceiptTaxIcon className="h-6 w-6 mr-4" />
                                        <span>
                                            Facturador
                                        </span>
                                    </button>
                                </li>
                                <li>
                                    <button type='button' onClick={() => handleChangeRoute('/quotes')} className="flex items-center w-full p-4 text-white group hover:text-white hover:bg-gray-800">
                                        <FolderOpenIcon className="h-6 w-6 mr-4" />
                                        <span>
                                            Cotizaciones
                                        </span>
                                    </button>
                                </li>
                                <li>
                                    <a target='_blank' href='/quoter' onClick={() => onClose()} className="flex items-center w-full p-4 text-white group hover:text-white hover:bg-gray-800">
                                        <CalculatorIcon className="h-6 w-6 mr-4" />
                                        <span>
                                            Cotizador
                                        </span>
                                    </a>
                                </li>
                                        */}
                                <li>
                                    <button type='button' onClick={handleClickLogout} className="flex items-center w-full p-4 text-white group hover:text-white hover:bg-gray-800">

                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-4" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z" clipRule="evenodd" />
                                        </svg>
                                        <span>
                                            SALIR
                                        </span>
                                    </button>

                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
}


export default DrawerComponent




