import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

function AuthGuard({ children, ...props }) {
    const { isLoggedIn, user } = useSelector((state) => state.auth);

    if (!isLoggedIn) {
        return <Navigate to="/login" />;
    }

    if (((props.profiels && !props.profiels?.includes(user.profile)) || (props.nprofiels && props.nprofiels.includes(user.profile)))) {
        return <Navigate to="/" />;
    }

    return children;

}

AuthGuard.propTypes = {
    children: PropTypes.any
};

export default AuthGuard;
